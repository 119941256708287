.navbar-brand {
    font-size: 50px !important;
}


@media screen and (max-width:768px) {
    .navbar-brand {
        font-size: 35px !important;
    }
}
@media screen and (max-width:450px) {
    .navbar {
        font-size: 50px !important;
        text-align: center;
        justify-content: center;
    }
}