@tailwind base;
@tailwind components;
@tailwind utilities;


@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Subrayada&family=Quicksand:wght@300;400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
html {
  --scrollbarBG: #292929;
  --thumbBG: #c7b654;
}
body::-webkit-scrollbar {
  width: 17px;
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
::-webkit-scrollbar-thumb {
  background-color: #c7b654; /* Set the background color of the thumb */
  border-radius: 4px; /* Set the border radius of the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #c7b6544b; /* Set the background color of the thumb on hover */
}


body {
 

  background: rgb(34,193,195);
background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
  animation: pulsate 4s ease-in-out infinite;
 background-attachment: fixed;
  overflow-x: hidden;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  margin: 0px;
  color: rgb(216, 216, 215);

  width: auto;
  height: auto;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}
 
.participate2 {
  background: rgba(254, 246, 0, 0.397);
  background: linear-gradient(0deg, rgba(254,247,0,1) 0%, rgba(253, 45, 45, 0.575) 100%);
}
 
.events {
  background: #30E8BF;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FF8235, #30E8BF);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FF8235, #30E8BF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
}
.button1 {
           

    background-image: linear-gradient(to right, #16A085 0%, #F4D03F  51%, #16A085  100%);
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: black;            

    border-radius: 32px;
    display: block;

  }

    .button1:hover {
    background-position: right center; /* change the direction of the change here */
    
    text-decoration: none;
  }
 
.participate {
  background: rgb(210,22,22);
background: radial-gradient(circle, rgba(210,22,22,1) 0%, rgba(224,253,45,1) 100%);
}
img {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 1024px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.nav_link:hover {
  color: #2fff00;
  transition: 0.5s;
}
#text1,
#text2 {
  position: absolute;
  width: 100%;
  display: inline-block;

  font-family: "Raleway", sans-serif;
  font-size: 50pt;

  text-align: center;

  user-select: none;
}

#container {
  position: absolute;
  margin: auto;
  width: 100vw;
  height: 80pt;
  top: 0;
  bottom: 0;

  filter: url(#threshold) blur(0.6px);
}

@keyframes pulsate {
  0% {
    background-size: 100% 100%;
    background-position: 50% 50%;
  }
  50% {
    background-size: 110% 110%;
    background-position: 50% 50%;
  }
  100% {
    background-size: 100% 100%;
    background-position: 50% 50%;
  }
}
.golden-glass-effect {
  background: linear-gradient(45deg, rgba(255, 217, 0, 0.123), rgba(255, 217, 0, 0.075));
  box-shadow: 0 4px 6px rgba(255, 215, 0, 0.1);
  border: 1px solid rgba(255, 217, 0, 0);
}